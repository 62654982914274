
import { defineComponent } from "vue";
import FormGenerator from "@/components/forms/form-generator.vue"; // @ is an alias to /src
import InputField from "@/components/forms/input-field.vue";
import SelectField from "@/components/forms/select-field.vue";
import TextareaField from "@/components/forms/textarea-field.vue";
import {
  emailValidator,
  requiredValidator,
  phoneValidator,
} from "../../validators";
import { RestResources } from "../../rest-resource";
const ApiResources = RestResources();

export default defineComponent({
  name: "GetQuote",
  components: {
    FormGenerator,
    InputField,
    SelectField,
    TextareaField,
  },
  data() {
    return {
      schema: [],
      button: "Submit",
      products: [],
      showError: false,
      errors: [],
      submitButton: {
        class: "btn bt-submit mt-5 mb-5 float-start d-none",
        value: "Update",
        loading: "Updating...",
      },
    };
  },
  async created() {
    this.schema = this.setFormData({});
    this.addProducts();
  },
  mounted() {
    this.setFormData({});
  },
  methods: {
    productErrorMessage(validator: any, field: any, i: any, index: any) {
      this.products[i][index].error = validator.validator(field) ? true : false;
      return validator.validator(field) ? validator.message : "";
    },
    updateForm(fieldName: any, value: any, i: any) {
      var foundIndex = this.products[i].findIndex(
        (x: any) => x.name == fieldName
      );
      this.products[i][foundIndex].value = value;
    },

    resourceChanged(data: any, product: any, field: any) {
      this.products[product][field].value = !data.resource
        ? data.field.value
        : data.resource[data.field.changedField];
    },
    addProduct() {
      this.validateProducts(true);
    },
    validateProducts(add = false) {
      if (this.products.length) {
        const error: any = [false];
        this.products.forEach((product: any, i: any) => {
          product.forEach((field: any, j: any) => {
            if (field.validators && field.validators.length) {
              field.validators.forEach((validator: any, k: any) => {
                if (validator.validator(field)) {
                  error[0] = true;
                  return;
                }
              });
            }
          });
        });

        if (!error[0]) {
          this.showError = false;
          if (add) {
            this.addProducts();
          } else {
            return "valid";
          }
        } else {
          this.showError = true;
        }
      }
    },
    submit() {
      (this.$refs["generatedform"] as any).onSubmitted();
      this.showError = true;
    },
    setFormData(data: any) {
      return [
        {
          fieldType: "InputField",
          placeholder: "Company Name",
          label: "",
          name: "companyname",
          type: "text",
          value: "",
          class: "form-group col-md-4 mb-2 text-secondary",
          defaultValue: "",
        },
        {
          fieldType: "InputField",
          placeholder: "First Name*",
          label: "",
          name: "firstname",
          type: "text",
          value: "",
          class: "form-group col-md-4 mb-2 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "First Name is required" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Last Name",
          label: "",
          name: "lastname",
          type: "text",
          value: "",
          class: "form-group col-md-4 mb-2 text-secondary",
          defaultValue: "",
        },
        {
          fieldType: "InputField",
          placeholder: "Phone Number*",
          label: "",
          name: "contact_number",
          type: "text",
          value: data.contact_number || "",
          class: "form-group col-md-4 mb-2 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Phone no is required" },
            {
              validator: phoneValidator,
              message: "Please enter valid phone number",
            },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Email ID",
          label: "",
          name: "email",
          type: "email",
          value: data.email || "",
          class: "form-group col-md-4 mb-2 text-secondary",
          defaultValue: "",
          validators: [
            {
              validator: emailValidator,
              message: "Please enter valid email id",
            },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Address",
          label: "",
          name: "address",
          type: "text",
          value: data.address || "",
          rows: 1,
          class: "form-group col-md-4 mb-2 text-secondary",
          defaultValue: "",
          validators: [
            {
              validator: requiredValidator,
              message: "Please enter address",
            },
          ],
        },
        {
          fieldType: "Fileupload",
          placeholder: "Images/Drawings",
          label: "",
          name: "files",
          type: "file",
          value: [],
          multiple: "multiple",
          class: "form-group col-md-12 mt-2 text-secondary text-start",
          style: "bg-white border",
          defaultValue: "",
        },
        {
          fieldType: "TextareaField",
          placeholder: "Message",
          label: "",
          name: "message",
          type: "text",
          value: "",
          rows: 3,
          class: "form-group col-md-12 mb-2 text-secondary",
          defaultValue: "",
        },
      ];
    },
    addProducts(data: any) {
      this.products.push([
        {
          fieldType: "SelectField",
          placeholder: "Product*",
          label: "",
          name: `name`,
          value: data && data.product_id ? data.product_id : "",
          class: "form-group col-md-6 mb-2 mt-4 text-secondary",
          defaultValue: data && data.product ? data.product.name : "",
          changedField: "name",
          changedFieldKey: "_id",
          options: [],
          isResource: true,
          resource: ApiResources.Products,
          page_size: 10,
          acceptAll: true,
          validators: [
            { validator: requiredValidator, message: "Product is required" },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Quantity*",
          label: "",
          name: "quantity",
          type: "text",
          value: "",
          class: "form-group col-md-3 mb-2 mt-2 text-secondary",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Quantity is required" },
          ],
        },
        {
          fieldType: "SelectField",
          placeholder: "Units",
          label: "",
          name: `units`,
          value: "",
          class: "form-group col-md-3 mb-2 mt-4 text-secondary",
          defaultValue: "",
          options: ["SFT", "RFT", "MTR", "SQ.MTR", "OTHER"],
        },
        {
          fieldType: "TextareaField",
          placeholder: "Description",
          label: "",
          name: "description",
          type: "text",
          value: "",
          rows: 3,
          class: "form-group col-md-12 mb-2 mt-2 text-secondary",
          defaultValue: "",
        },
      ]);
    },
    async validForm(data: any) {
      const valid = this.validateProducts();
      if (valid == "valid") {
        const products_list: any = [];
        const validatedForm: any = {};
        data.forEach((element: any) => {
          validatedForm[element.name] = element.value;
        });
        this.products.forEach((product: any) => {
          let values: any = {};
          product.forEach((field: any) => {
            values[field.name] = field.value;
          });
          products_list.push(values);
          // if(product)
        });
        let formData = new FormData();
        formData.append("companyname", validatedForm.companyname);
        formData.append("firstname", validatedForm.firstname);
        formData.append("lastname", validatedForm.lastname);
        formData.append("contact_number", validatedForm.contact_number);
        formData.append("email", validatedForm.email);
        formData.append("address", validatedForm.address);
        formData.append("message", validatedForm.message);

        for (var i = 0; i < validatedForm.files.length; i++) {
          let file = validatedForm.files[i];
          formData.append("files", file);
        }
        formData.append("products", JSON.stringify(products_list));

        try {
          let resourceEndPoint = ApiResources.Quotes.wrap("/");
          let result = await resourceEndPoint.post(formData);
          this.callRestForm();

          this.displayMessage(
            result.data.message,
            result.status == 200 ? "white" : "warning"
          );
        } catch (err: any) {
          this.callRestForm(false);
          this.displayMessage(err.response.data.message, "danger");
        }
      }
    },
    removeProduct(i: number) {
      if (i != 0) {
        this.products.splice(i, 1);
      }
    },
    callRestForm(reset = true) {
      this.showError = false;
      this.products.forEach((product: any, i: any) => {
        (this.$refs[`product-${i}`] as any)[0].resetForm();
        console.log(this.$refs, (this.$refs[`product-${i}`] as any)[0]);
      });
      this.products.length = 0;
      this.addProducts();
      (this.$refs["generatedform"] as any).resetForm(reset);
    },
  },
});
